<template>
  <div
    :class="wrapperClasses"
    class="e-overall"
  >
    <transition name="canvas">
      <div
        v-show="!offcanvasOpen"
        class="e-canvas"
      >
        <div
          v-if="!userInfo || !userInfo.id"
          class="e-canvas__background"
        />

        <TheHeader />

        <div class="e-content">
          <TheSidebar />
          <VuePerfectScrollbar class="e-content__inner e-content__inner--wide">
            <div class="e-content__scroll">
              <div class="e-module e-module--limited e-module--landing">
                <article class="e-module__column">
                  <router-link :to="{name: 'dialogs'}" class="e-module__icon plain-link">
                    <icon-mailsupport />
                  </router-link>
                  <div class="e-module__header">
                    <h1 class="e-module__title">
                      <router-link :to="{name: 'dialogs'}" class="plain-link">
                        {{ $t('system.componentEmail') }}
                      </router-link>
                    </h1>
                  </div>
                  <div
                    class="e-module__content"
                    v-html="mailText"
                  />
                  <footer class="e-module__footer">
                    <router-link
                      :to="{name: 'dialogs'}"
                      class="e-btn e-btn--tertiary"
                    >
                      {{ $t('system.toComponentEmail') }}
                    </router-link>
                  </footer>
                </article>
                <article
                  v-if="!chatIsDisabled"
                  :class="{
                    'e-chat--offline': !chatIsOpen
                  }"
                  class="e-module__column"
                >
                  <template v-if="chatIsOpen">
                    <router-link :to="{name: 'liveChat'}" class="plain-link e-module__icon">
                      <icon-chat />
                    </router-link>
                  </template>
                  <template v-else>
                    <div class="e-module__icon">
                      <icon-chat />
                    </div>
                  </template>
                  <div class="e-module__header">
                    <h1 class="e-module__title">
                      <template v-if="chatIsOpen">
                        <router-link :to="{name: 'liveChat'}" class="plain-link">
                          {{ $t('system.componentChat') }}
                        </router-link>
                      </template>
                      <template v-else>
                          {{ $t('system.componentChat') }}
                      </template>
                    </h1>
                  </div>
                  <div class="e-module__content">
                    <div v-html="chatText" />
                    <table v-if="!chatIsOpen && upcomingchatEvents.length">
                      <tbody>
                        <tr
                          v-for="chatEvent in sortedUpcomingChatEvents"
                          :key="chatEvent.id"
                        >
                          <td valign="top">
                            {{ format(chatEvent.beginning, 'EEEEEE, dd.MM.') }}
                            {{ format(chatEvent.beginning, 'HH:mm') }}-{{ format(chatEvent.end, 'HH:mm') }} Uhr
                            <span v-if="chatEvent.subject"> | Thema: {{ chatEvent.subject }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <footer class="e-module__footer">
                    <div class="link">
                      <button
                        v-if="!chatIsOpen"
                        :aria-disabled="!chatIsOpen"
                        :disabled="!chatIsOpen"
                        class="e-btn is-disabled"
                      >
                        {{ $t('system.toComponentChat') }}
                      </button>
                      <router-link
                        v-else
                        :to="{name: 'liveChat'}"
                        class="e-btn e-btn--tertiary"
                      >
                        {{ $t('system.toComponentChat') }}
                      </router-link>
                    </div>
                    <!-- <div class="chat-archive-link">
                      <router-link
                        v-if="userInfo && userInfo.chatEventCount>0"
                        :to="{name: 'chatDialogs'}"
                        class=""
                      >
                        Meine archivierten Chats
                      </router-link>
                    </div> -->
                  </footer>
                </article>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>

        <el-dialog
          v-if="announcement && announcement.title && announcement.active"
          :title="announcement.title"
          :visible.sync="currentInfoVisible"
          :before-close="setMessageRead"
          custom-class="el-dialog--small"
        >
          <div
            v-html="announcement.text"
            v-loading="pageDataLoading"
          />
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button
              type="tertiary"
              @click="setMessageRead"
            >
              {{ $t('system.understood') }}
            </el-button>
          </span>
        </el-dialog>

        <TheFooter hide-logos />
      </div>
    </transition>

    <transition name="offcanvas">
      <TheOffcanvas v-show="offcanvasOpen" />
    </transition>

    <ChatIdleConfirm />
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns'

import { mapGetters } from 'vuex'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import TheFooter from 'molecules/TheFooter/TheFooter'
import TheHeader from 'molecules/TheHeader/TheHeader'
import TheSidebar from 'molecules/TheSidebar/TheSidebar'
import TheOffcanvas from 'molecules/TheOffcanvas/TheOffcanvas'
import ChatIdleConfirm from 'organisms/ChatIdleConfirm/ChatIdleConfirm'
import IconChat from 'assets/icons/chat-solid.svg'
import IconMailsupport from 'assets/icons/mailsupport.svg'

export default {
  components: {
    IconChat,
    IconMailsupport,
    ChatIdleConfirm,
    TheFooter,
    TheHeader,
    TheSidebar,
    TheOffcanvas,
    VuePerfectScrollbar
  },

  data: () => ({
    currentInfoVisible: false,
    loading: false
  }),

  computed: {
    ...mapGetters([
      'announcement', 'chatIsOpen', 'currentChatEvent', 'userInfo', 'offcanvasOpen', 'landingTexts', 'language', 'pageDataLoading', 'dateLocale', 'chatIsDisabled']),
    mailText () {
      return this.landingTexts.email
    },
    chatText () {
      return this.chatIsOpen ? this.landingTexts.chatOpen + (this.currentChatEvent.subject !== '' ? '<br>Thema: ' + `${this.currentChatEvent.subject}` : '') : this.landingTexts.chatClosed
    },
    upcomingchatEvents () {
      return window.upcomingChatEvents
    },
    sortedUpcomingChatEvents () {
      return [...window.upcomingChatEvents].sort((a, b) =>
        new Date(a.beginning) - new Date(b.beginning)
      )
    },
    wrapperClasses () {
      let classes = {
        'e-overall--loggedin': this.userInfo && this.userInfo.id
      }
      return classes
    }
  },
  watch: {
    chatIsDisabled (newVal) {
      if (newVal) {
        this.$router.push({ name: 'dialogs' })
      }
    }
  },

  created () {
    if (this.chatIsDisabled) {
      this.$router.push({ name: 'dialogs' })
    }
  },

  mounted () {
    if (!this.$cookie.get('messageRead')) {
      this.currentInfoVisible = true
    }
  },

  methods: {
    setMessageRead () {
      this.$cookie.set('messageRead', true, 1)
      this.currentInfoVisible = false
    },
    format (date, token) {
      return format(parseISO(date), token, {
        locale: this.dateLocale
      })
    }
  }

}
</script>

<style lang="scss" scoped>
@import '_assets/LoginLanding';
</style>
